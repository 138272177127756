import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/packages/web/assets/cloudBig.webp");
;
import(/* webpackMode: "eager" */ "/app/packages/web/assets/cloudBot.webp");
;
import(/* webpackMode: "eager" */ "/app/packages/web/assets/dude-on-mountain.webp");
;
import(/* webpackMode: "eager" */ "/app/packages/web/src/ui/client.ts");
;
import(/* webpackMode: "eager", webpackExports: ["NavLink"] */ "/app/packages/web/src/ui/common/navigation/nav-link/nav-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNavigation"] */ "/app/packages/web/src/ui/common/navigation/navigations/mobile-navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Profile"] */ "/app/packages/web/src/ui/common/navigation/profile/profile.tsx");
